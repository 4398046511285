<template>
  <div class="tab-pane__content">
    <div class="toolbar__container">
      <div class="toolbar-left">
        <div :class="toolbarQrcodeClasses" @click="showBadgeModal">
          <b-img fluid :src="badgeUri" :class="badgeClasses" />
        </div>
        <div class="toolbar-profile">
          <user-switcher
            :hide-switch="hideSwitch"
            unique-id="profileSwitchToolbarModal"
            showEmail
          />
        </div>
      </div>
      <div class="d-flex flex-grow-1">
        <div class="toolbar-icons">
          <div v-if="isOperative">
            <div
              @click="routeTo('user-stats')"
              v-b-tooltip.hover
              title="Badge & Statistiche"
            >
              <b-img :src="iconReviewSwap" />
            </div>
          </div>
          <div v-if="isOperative">
            <div
              @click="routeTo('user-report-history')"
              v-b-tooltip.hover
              title="Elenco segnalazioni"
            >
              <b-img :src="iconReview2" />
            </div>
          </div>
          <div
            v-if="isOperative"
            @click="routeTo('user-analysis-history')"
            v-b-tooltip.hover
            title="Elenco richieste"
          >
            <div>
              <b-img :src="iconFinancial" />
            </div>
          </div>
        </div>
        <div class="toolbar-icons ms-auto" v-if="!hideButton">
          <div
            @click="routeTo('wallet')"
            class="toolbar-icons__icon toolbar-icons__icon--wallet"
            v-b-tooltip.hover
            title="Wallet"
          >
            <div>
              <b-img :src="iconWallet" />
            </div>
            <div class="white-space-nowrap flex-grow-1 ms-2 d-none d-sm-inline-block d-md-none d-lg-inline-block">
              <h6 class="mb-0">Credito disponibile</h6>
              <p class="mb-0">{{walletAmount}} {{walletCurrency}}</p>
            </div>
          </div>
          <div
            class="toolbar-icons--icon-settings"
            @click="routeTo('user-data')"
            v-b-tooltip.hover
            title="Anagrafica"
          >
            <b-img :src="iconSettingsSwap" />
          </div>
        </div>
        <div class="toolbar-icons ms-auto align-items-center" v-if="hasExtraActions">
          <b-button v-if="hasCreateReport" @click="routeTo('create-report')">
            Effettua una segnalazione
          </b-button>
          <b-button v-if="hasCreateAnalysis" @click="routeTo('request-analysis')">
            Richiedi un servizio
          </b-button>
        </div>
      </div>
    </div>
    <modal-badge-qr-code id="showBadgeToolbar" :serialCode="serialCode" />
    <modal-badge-setting id="badgeConfigToolbar" />
  </div>
</template>

<script>
import { createBadgeUri, createPublicUri } from '../../utils/utilities';
import { isNotEmpty } from '../../utils/validators';

const ModalBadgeQrCode = () => import('@/components/userSections/ModalBadgeQrCode.vue');
const ModalBadgeSetting = () => import('@/components/userSections/ModalBadgeSetting.vue');
const UserSwitcher = () => import('@/components/userSwitcher/UserSwitcher.vue');

export default {
  name: 'UserToolbar',
  components: {
    ModalBadgeQrCode,
    ModalBadgeSetting,
    UserSwitcher,
  },
  props: {
    serialCode: String,
    hideButton: Boolean,
    hideSwitch: Boolean,
    hasCreateAnalysis: Boolean,
    hasCreateReport: Boolean,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      userPlaceholder: require('@/assets/img/user-img_placeholder.png'),
      // eslint-disable-next-line global-require
      companyPlaceholder: require('@/assets/img/company-img_placeholder.png'),
      // eslint-disable-next-line global-require
      iconFinancial: require('@/assets/img/financial.svg'),
      // eslint-disable-next-line global-require
      iconMarket: require('@/assets/img/market-analysis.svg'),
      // eslint-disable-next-line global-require
      iconOffice: require('@/assets/img/office-building.svg'),
      // eslint-disable-next-line global-require
      iconQuality: require('@/assets/img/quality-control.svg'),
      // eslint-disable-next-line global-require
      iconReview: require('@/assets/img/review.svg'),
      // eslint-disable-next-line global-require
      iconReview2: require('@/assets/img/review_2.svg'),
      // eslint-disable-next-line global-require
      iconSettings: require('@/assets/img/settings.svg'),
      // eslint-disable-next-line global-require
      iconWallet: require('@/assets/img/wallet2.png'),
    };
  },
  computed: {
    badgeUri() {
      return createBadgeUri(this.serialCode);
    },
    placeholderImg() {
      if (this.isCompanyProfile) return this.companyPlaceholder;
      return this.userPlaceholder;
    },
    profileImgUrl() {
      const imageProfile = this.$store.getters['subject/getImage']('PROFILE');
      return isNotEmpty(imageProfile)
        ? createPublicUri(imageProfile.documentUpload.externalId)
        : this.placeholderImg;
    },
    personImgUrl() {
      const imageProfile = this.$store.getters['subject/getPersonImage']('PROFILE');
      return isNotEmpty(imageProfile)
        ? createPublicUri(imageProfile.documentUpload.externalId)
        : this.placeholderImg;
    },
    profileImgStyle() {
      return `background-image: url(${this.profileImgUrl});`;
    },
    personImgStyle() {
      return `background-image: url(${this.personImgUrl});`;
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    badgeClasses() {
      if (this.activityStatus.operative === false) return 'has-filter--blur';
      return '';
    },
    toolbarQrcodeClasses() {
      if (this.activityStatus.operative === false) return 'pointer-events-none toolbar-qrcode';
      return 'toolbar-qrcode';
    },
    isOperative() {
      return this.activityStatus.operative && !this.hideButton;
    },
    iconSettingsSwap() {
      if (this.isCompanyProfile) return this.iconOffice;
      return this.iconSettings;
    },
    iconReviewSwap() {
      if (this.isCompanyProfile) return this.iconQuality;
      return this.iconReview;
    },
    cardProfileUserClasses() {
      if (this.hideSwitch) return 'card card--profile pointer-events--none';
      return 'card card--profile';
    },
    cardProfileActionClasses() {
      if (this.hideSwitch) return 'card--profile__action card--profile__action--simple d-none';
      return 'card--profile__action card--profile__action--simple';
    },
    hasExtraActions() {
      return this.hasCreateReport || this.hasCreateAnalysis;
    },
    walletAmount() {
      return this.$store.getters['wallet/getWallet']?.amount.amount || 0;
    },
    walletCurrency() {
      return this.$store.getters['wallet/getWallet']?.amount.currency || '';
    },
  },
  methods: {
    changeProfile() {
      this.$bvModal.show('profileSwitchToolbar');
    },
    showBadgeModal() {
      this.$bvModal.show('showBadgeToolbar');
    },
    openConfig() {
      this.$bvModal.show('badgeConfigToolbar');
    },
    setDashboardState(state) {
      this.$store.dispatch('setDashboard', state);
    },
    routeTo(path) {
      this.$router.replace(path);
    },
  },
};
</script>

<style scoped>

</style>
